var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex flex-column align-center full-height full-width"},[_c('PageTitle',{attrs:{"icon":"mdi-clipboard-edit","title":_vm.$tc('Details.title'),"loading":_vm.loader},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('v-btn',{directives:[{name:"can",rawName:"v-can:create",value:([_vm.$constants.PRIVILEGES.DETAIL]),expression:"[$constants.PRIVILEGES.DETAIL]",arg:"create"}],attrs:{"color":"cta white--text","block":_vm.$vuetify.breakpoint.xsOnly},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" "+_vm._s(_vm.$tc("Details.createTitle"))+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"right":""}},[_vm._v(" mdi-plus ")])],1)]},proxy:true}])}),_c('v-row',{class:_vm.details.length >= 0 && !_vm.loader
        ? 'full-width flex-grow-0'
        : 'full-width flex-grow-1'},[_c('v-col',{staticClass:"d-flex justify-center"},[(_vm.loader)?_c('Loader',{attrs:{"message":_vm.$t('Loader.details')}}):(!_vm.loader)?_c('v-card',{staticClass:"custom-card",attrs:{"elevation":"8"}},[_c('bar-search',{attrs:{"search":_vm.search,"readonly":_vm.loadingDT,"searchCallback":_vm.searchDetailByText,"label":_vm.$tc('Details.searchBar')},on:{"update:search":function($event){_vm.search=$event}}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{staticClass:"customDT",attrs:{"headers":_vm.headers,"items":_vm.details,"server-items-length":_vm.pagination.totalItems,"loading":_vm.loadingDT,"footer-props":{
            itemsPerPageText: _vm.$tc('Details.detailsPerPage'),
            pageText: ("{0}-{1} " + (_vm.$tc('Details.detailsByPage')) + " {2}"),
            'items-per-page-all-text': _vm.$tc('Details.allDetails'),
          },"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"info","indeterminate":"","absolute":""}})]},proxy:true},{key:"no-data",fn:function(){return [_c('p',{staticClass:"ma-6"},[_vm._v(" "+_vm._s(_vm.$tc("Details.emptySearch"))+" ")])]},proxy:true},{key:"loading",fn:function(){return [_c('p',{staticClass:"ma-6"},[_vm._v(_vm._s(_vm.$tc("Details.loadingSearch")))])]},proxy:true},{key:"item.type",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$tc("Details.Type." + item.type.toLowerCase()))+" ")]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(_vm._f("status")(item.status.name)))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('DotsMenu',{attrs:{"options":[
                {
                  title: _vm.$tc('Action.update'),
                  icon: 'mdi-pencil',
                  event: 'update',
                  permissions: {
                    list: [_vm.$constants.PRIVILEGES.DETAIL],
                    type: 'U',
                  },
                },
                {
                  title: _vm.$tc('Action.delete'),
                  icon: 'mdi-delete',
                  event: 'delete',
                  permissions: {
                    list: [_vm.$constants.PRIVILEGES.DETAIL],
                    type: 'D',
                  },
                } ]},on:{"update":function($event){return _vm.editDetail(item)},"delete":function($event){return _vm.deleteDetailConfirm(item)}}})]}}],null,true)})],1):_vm._e()],1)],1),_c('DetailForm',{attrs:{"dialog":_vm.dialog,"editDetail":_vm.detail},on:{"closeDialog":_vm.close}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 error white--text"},[_vm._v(_vm._s(_vm.$tc("Details.deleteTitle")))]),_c('v-card-text',{staticClass:"py-6 text-center"},[_vm._v(" "+_vm._s(_vm.$t("Details.deleteQuestion", { name: _vm.detail.name }))+" ")]),_c('v-divider'),_c('v-card-actions',{staticClass:"py-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"same-size-btn mr-2",attrs:{"color":"error"},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$tc("Action.cancel")))]),_c('v-btn',{staticClass:"same-size-btn",attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.deleteDetail}},[_vm._v(_vm._s(_vm.$tc("Action.delete")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }